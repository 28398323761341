import React from "react"
import PropTypes from 'prop-types'

// import redux
import { connect } from "react-redux"

// import Link
import { Link } from "react-router-dom"

// reactstrap
import { Row, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

// import logo
import logo from "../../assets/images/logos/logo.png"

// import custom dropdowns components
import Notifications from "components/dropdowns/Notifications"
import Profile from "components/dropdowns/Profile"


class Header extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            screenFulled: false,
            withFullScreenMenu: false,
            withNotificationsMenu: false
        }
    }

    /**
     * Fullscreen toggle events.
     * Alternative standard method.
     */
    onFullscreenToggled = () => {
        // Update state
        this.setState(prevState => ({ screenFulled: !prevState.screenFulled }))

        // Fullscreen process
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            if (document.documentElement.requestFullscreen) {// current working methods
                document.documentElement.requestFullscreen()
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen()
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen()
            }
        }
    }


    render() {
        const { menuOpened, onMenuOpened } = this.props
        const { screenFulled, withNotificationsMenu, withFullScreenMenu } = this.state

        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <Link to="/" className="logo logo-dark lh-1">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logo} alt="" height="60" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light lh-1">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="30" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logo} alt="" height="60" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                type="button"
                                className="btn btn-sm px-3 fs-16 d-lg-none header-item"
                                data-toggle="collapse"
                                onClick={onMenuOpened}
                                data-target="#topnav-menu-content"
                            >
                                <i className="fa fa-fw fa-bars" />
                            </button>
                        </div>

                        <div className="d-flex">
                            {/* Fullscreen Toggle Button */}
                            {withFullScreenMenu ? (
                                <div className="dropdown header-item px-1 center-box d-none d-lg-inline-flex me-1">
                                    <button
                                        type="button"
                                        onClick={this.onFullscreenToggled}
                                        className="btn btn-sm noti-icon border-0"
                                        data-toggle="fullscreen"
                                    >
                                        <i className={`fa fa-${screenFulled ? "compress" : "expand"} text-muted fw-bold`} />
                                    </button>
                                </div>
                            ) : null}

                            {/* Notification Dropdown */}
                            {withNotificationsMenu ? (<Notifications />) : null}

                            {/* Profile Dropdown */}
                            <Profile />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}
Header.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
}
export default (withTranslation()(Header))