import {
    INVOICES_ERROR, INVOICES_FIND, INVOICES_RESET, INVOICES_SELECT, INVOICES_SET, INVOICES_SUCCESS, INVOICES_UPDATE
} from "./constants"


export const listInvoices = ( option) => ({
    type: INVOICES_SELECT,
    payload: { option },
})

export const searchInvoices = (reference, month, year, option) => ({
    type: INVOICES_FIND,
    payload: { reference, month, year, option },
})

export const editInvoice = (data, option) => ({
    type: INVOICES_UPDATE,
    payload: { data, option },
})

export const successInvoices = (data, option) => ({
    type: INVOICES_SUCCESS,
    payload: { data, option },
})

export const errorInvoices = (error = "", option = "") => ({
    type: INVOICES_ERROR,
    payload: { error, option },
})

export const setInvoices = (data) => ({
    type: INVOICES_SET,
    payload: { ...data },
})

export const resetInvoices = (all) => ({
    type: INVOICES_RESET,
    payload: { all },
})