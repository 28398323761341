import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import { resetOrders, setOrders, showOrder } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Loader from 'components/common/Loader'

// import custom orders components
import OrderReceipt from 'components/orders/OrderReceipt'

// import constants
import { APP_NAME, ORDERS_SHOW } from 'helpers/constants'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class MyReceipt extends React.Component {

    componentDidMount() {
        try {//Load order
            this.onLoaded()
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {
            // Get values
            let { onResetOrders } = this.props

            // Reset state
            onResetOrders(true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On loaded.
     * 
     */
    onLoaded = () => {
        try {
            //Get props
            let { onShowOrder, match } = this.props

            //Load order details
            onShowOrder(match.params.order)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    render() {
        const { t, option, error, loading, order } = this.props

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Accusé de réception")}</title>
                </Helmet>

                <Container>
                    <Row className="mb-2">
                        <Col xl="12" className="py-5">
                            {((error != "") && (option === ORDERS_SHOW)) ? (
                                <Alert color="danger" className="d-flex align-items-center justify-content-between m-0">
                                    {error} {" "}
                                    <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                        Réessayer
                                    </Link>
                                </Alert>
                            ) : (loading && (option === ORDERS_SHOW)) ? (
                                <center>
                                    <Loader color="primary" />
                                    <p className="m-0 p-2">
                                        Génération de l'accusé de réception de votre course. Veuillez patienter...
                                    </p>
                                </center>
                            ) : (
                                <OrderReceipt order={order} />
                            )}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }

}
MyReceipt.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    order: PropTypes.object,
    onShowOrder: PropTypes.func,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onShowOrder: (id) => dispatch(showOrder(id, null, ORDERS_SHOW))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(MyReceipt)))