import React from 'react'

// import components
import { Row, Col } from "reactstrap"

// import constants
import { APP_NAME } from "helpers/constants"

const Footer = () => {

    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container-fluid">
                    <Row>
                        <Col md="6" sm="12">
                            <span>
                                {new Date().getFullYear()} © {APP_NAME}.
                            </span>
                            <span className="ms-2">
                                Support : <strong>(+229) 51 21 11 11</strong>
                            </span>
                        </Col>
                        <Col md="6" sm="12">
                            <div className="text-sm-end d-none d-sm-block">
                                Tous droits réservés.
                                <a
                                    href="/confidentialite-et-utilisation"
                                    className="ms-2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Confidentialité &amp; Utilisation
                                </a>.
                            </div>
                        </Col>
                    </Row>
                </div>
            </footer>
        </React.Fragment>
    )

}
export default Footer
