import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Alert, Card, CardBody, CardHeader, CardTitle, Col, Row, Input } from 'reactstrap'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import constants
import {
    ORDERS_ASSIGNED, ORDERS_PENDING, ORDERS_REPORTED, ORDERS_STARTED, ORDERS_PER_PAGE, ORDERS_COMPLETED
} from 'helpers/constants'

// import utilities
import { consoleErrorMessage, formatPhoneNumberForDisplay, getConstantByKey, upperFirst } from 'helpers/utilities'

// import custom common components
import Loader from '../common/Loader'
import Paginator from '../common/Paginator'
import Filter from '../common/Filter'


class OrderList extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentOrders: [],
            currentPage: 0,
            totalPages: 0,
            query: ""
        }
    }

    /**
     * onPageChanged.
     * 
     * @param {*} data 
     */
    onPageChanged = data => {
        const { orders, totalPerPage } = this.props
        const { currentPage, totalPages } = data

        const offset = (currentPage - 1) * totalPerPage
        const currentOrders = orders.slice(offset, (offset + totalPerPage))

        this.setState({ currentOrders, currentPage, totalPages })
    }

    /**
     * onChanged.
     * 
     * @param {*} e 
     */
    onChanged = (e) => {
        try {
            //Get input value
            let search = ((e && e.target) ? e.target.value.trim().toLowerCase() : "")

            //Get props valies
            let { alls, onRefreshed } = this.props

            //Search orders
            let searches = alls
            if (search !== "") {
                searches = alls.filter((ox) => {
                    let { start_point, delivery_point, created_at, code, payment, nature } = ox

                    let ox_str = `${code} ${nature} ${start_point.contact} ${start_point.address}`
                    ox_str += ` ${delivery_point.contact} ${delivery_point.address} ${payment.price}`
                    ox_str += ` ${moment.unix(created_at).format("DD MMM YYYY à HH:mm")}`

                    return ox_str.toLowerCase().includes(search)
                })
            }

            //Update states
            this.setState({ query: search }, () => {
                onRefreshed(searches)
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * onFiltered.
     * 
     * @param {*} selected 
     */
    onFiltered = (selected) => {
        try {
            //Reset search views
            if (this.state.query !== "") {
                this.setState({ query: "" }, () => {
                    document.getElementById("search-orders").value = ""
                })
            }

            //Get props values
            let { alls, filter, onRefreshed } = this.props

            //Filter orders
            let filtereds = alls
            if (selected.key !== filter.key) {
                filtereds = alls.filter((item) => {
                    let { current_status, current_user_id, reported } = item
                    if (selected.key === ORDERS_ASSIGNED.key) {
                        return (
                            current_status && (current_status === selected.key) && current_user_id && (current_user_id !== "")
                        )
                    } else if (selected.key === ORDERS_REPORTED.key) {
                        return (
                            current_status && (current_status === ORDERS_STARTED.key) && reported
                        )
                    } else {
                        return (
                            current_status && (current_status === selected.key)
                        )
                    }
                })
            }

            // Update state
            onRefreshed(filtereds, ((selected.key !== filter.key) ? selected : {}))
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    render() {
        const { alls, orders, filter, itemCol, totalPerPage, withFilter, withSearch, onShowed, showReload, onReloaded } = this.props
        const { currentOrders, currentPage, totalPages, query } = this.state

        return (
            <React.Fragment>
                <Col xl="12" sm="12" className="mt-2 mt-xl-0">
                    <Card className="shadow-none orders-header">
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col xl="7" md="12">
                                    {(alls && (alls.length > 3) && withFilter) ? (
                                        <>
                                            <Filter
                                                items={[ORDERS_PENDING, ORDERS_ASSIGNED, ORDERS_STARTED, ORDERS_REPORTED, ORDERS_COMPLETED]}
                                                selected={filter}
                                                onFiltered={this.onFiltered}
                                            />

                                            {(filter && filter.key && orders) ? (
                                                <small className="d-block text-muted fw-bolder mt-1">
                                                    {`${(orders.length === 0) ? "Aucune" : orders.length} 
                                                        course${(orders.length > 1) ? "s" : ""} 
                                                        ${filter[(orders.length > 1) ? "label" : "name"].toLowerCase()}`}
                                                </small>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Col>
                                <Col xl="5" md="12" className="text-end">
                                    {(showReload && onReloaded) ? (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm m-1 text-muted center-ibox"
                                            onClick={() => onReloaded()}
                                        >
                                            Actualiser
                                            <i className="bx bx-reset text-muted ms-1" />
                                        </button>
                                    ) : null}
                                </Col>
                            </Row>

                            <Row className="align-items-center mt-2 p-1">
                                <Col xl="3" className="p-2">
                                    {withSearch ? (
                                        <>
                                            <div className="search-box">
                                                <div className="position-relative">
                                                    <Input
                                                        id="search-orders"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Rechercher..."
                                                        onChange={(e) => this.onChanged(e)}
                                                        disabled={(alls && (alls.length === 0))}
                                                    />
                                                    <i className="bx bx-search-alt search-icon" />
                                                </div>
                                            </div>

                                            {(query && (query !== "") && orders) ? (
                                                <small className="d-block text-muted fw-bolder fs-10 mt-1">
                                                    {`${(orders.length === 0) ? "Aucune" : orders.length} 
                                                            ${(orders.length > 1) ? "courses retrouvées" : "course retrouvée"}`}
                                                </small>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Col>
                                <Col xl="9" className="p-2 text-end">
                                    <Paginator
                                        pageNeighbours={1}
                                        items={orders}
                                        totalItems={orders.length}
                                        itemsPerPage={totalPerPage}
                                        paginationClasses="mb-0 justify-content-end"
                                        onPageChanged={this.onPageChanged}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>

                    <Row>
                        {(currentOrders && (currentOrders.length > 0) && currentOrders.map((item, index) => (
                            <OrderItem
                                key={`item_order_${index}`}
                                col={itemCol}
                                status={getConstantByKey(item.current_status)}
                                onSelected={onShowed}
                                {...item}
                            />
                        )))}
                        {((!currentOrders) || (currentOrders.length === 0)) ? (
                            <Col xl="12">
                                <Alert color="secondary"> Aucune course à afficher </Alert>
                            </Col>
                        ) : null}
                    </Row>
                </Col>
            </React.Fragment>
        )
    }

}
OrderList.propTypes = {
    alls: PropTypes.array,
    orders: PropTypes.array,
    filter: PropTypes.object,
    showReload: PropTypes.bool,
    withFilter: PropTypes.bool,
    withSearch: PropTypes.bool,
    itemCol: PropTypes.number,
    totalPerPage: PropTypes.number,
    onShowed: PropTypes.func,
    onReloaded: PropTypes.func,
    onRefreshed: PropTypes.func
}
OrderList.defaultProps = {
    showReload: false,
    withFilter: false,
    withSearch: true,
    itemCol: 4,
    totalPerPage: ORDERS_PER_PAGE,
    alls: [],
    orders: [],
    filter: {}
}
export default OrderList


const OrderItem = ({ col, _id, code, start_point, delivery_point, created_at, nature, payment, status, reported, rts, delivery_auth, refund, onSelected }) => (
    <React.Fragment>
        <Col xl={`${col}`} md="6" sm="12">
            <Card>
                <CardBody className="p-0">
                    <div className="order-item">
                        <div className="order-header d-flex align-items-center justify-content-between border-bottom py-2 px-3">
                            <div className="order-code me-1">
                                <span
                                    className="d-block text-primary text-decoration-underline h-cursor"
                                    onClick={() => onSelected({ _id, delivery_auth })}
                                >
                                    Course <br className="d-none" /> #{code}
                                </span>
                                <span className="d-block fs-12" title="Date">
                                    <small>du</small> {" "}
                                    <span className="fw-bold">
                                        {moment.unix(created_at).format("DD MMM YYYY")}
                                    </span> {" "}
                                    <br className="d-none" />
                                    <small>à</small>{" "}
                                    <span className="fw-bold">
                                        {moment.unix(created_at).format("HH:mm")}
                                    </span>
                                </span>
                            </div>
                            <div className="order-cost ms-1">
                                <span className="d-block fs-13 fw-bolder" title="Coût">
                                    {payment.price}<small>(XOF)</small>
                                </span>
                                {(refund && refund.status) ? (
                                    <span className="d-block fw-bold text-danger">
                                        Remboursée
                                    </span>
                                ) : null}
                            </div>
                        </div>

                        <div className="order-body p-3">
                            <div className="d-flex orders-points">
                                <span className="points-box start-point me-1">
                                    <i className="bx bxs-map text-orange" />
                                </span>
                                <div className="flex-grow-1">
                                    <span className="d-block fs-12 text-muted fw-bolder">
                                        {formatPhoneNumberForDisplay(start_point.contact)}
                                    </span>
                                    <span className="d-block fs-13 fw-bolder ellipsis-text">
                                        {start_point.address}
                                    </span>
                                </div>
                            </div>
                            <div className="dash-separator mx-2" />
                            <div className="d-flex orders-points mt-3">
                                <span className="points-box end-point me-1">
                                    <i className="bx bxs-map text-blue" />
                                </span>
                                <div className="flex-grow-1">
                                    <span className="d-block fs-12 text-muted fw-bolder">
                                        {formatPhoneNumberForDisplay(delivery_point.contact)}
                                    </span>
                                    <span className="d-block fs-13 fw-bolder ellipsis-text">
                                        {delivery_point.address}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="order-footer d-flex align-items-center justify-content-between border-top py-2 px-3">
                            <span className="d-block fs-12 fw-bolder ellipsis-text" title="Nature">
                                {upperFirst(nature)}
                            </span>
                            <div className="d-flex align-items-center justify-content-center ms-1">
                                <span className={`badge bg-soft bg-${status.color} text-${status.color} fw-bold`}>
                                    {status.name}
                                </span>
                                {((status.key === ORDERS_STARTED.key) && reported) ? (
                                    <span className="ms-2" title="Incident en cours d'acheminement">
                                        <i className='bx bxs-error-alt bx-xs text-danger' />
                                    </span>
                                ) : null}
                                {(rts && rts.enabled) ? (
                                    <span className="ms-2 badge bg-soft bg-secondary text-secondary fw-bold">
                                        RTS
                                    </span>
                                ) : null}
                                {(delivery_auth && delivery_auth.enabled) ? (
                                    <span className="ms-1" title="Double confirmation">
                                        <i className='bx bx-check-double bx-xs text-blue' />
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Col>
    </React.Fragment>
);