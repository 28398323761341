import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import { listInvoices, resetInvoices, setInvoices } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'
import Reader from 'components/common/Reader'

// import constants
import { APP_NAME, INVOICES_EDIT, INVOICES_LIST, INVOICES_READ } from 'helpers/constants'

// import custom components
import InvoiceList from 'components/invoices/InvoiceList'
import InvoiceCard from 'components/invoices/InvoiceCard'
import InvoicePay from 'components/invoices/InvoicePay'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class MyInvoices extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			cardVisible: false,
			payVisible: false,
			readVisible: false
		}
	}

	componentDidMount() {
		try {// Load invoices
			this.onLoaded()
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	componentDidUpdate(prevProps) {
		try {
			//Check state updates
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	componentWillUnmount() {
		try {// Reset state
			this.props.onResetInvoices(true)
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	/**
	 * Toggle form modal.
	 * 
	 * @param {*} option 
	 */
	onToggled = (option = "cardVisible", visible = false) => {
		this.setState({ [option]: visible })
	}

	/**
	 * On loaded.
	 * Load invoices list.
	 * 
	 */
	onLoaded = () => {
		try {//Load list
			let { alls, onListInvoices, onResetInvoices } = this.props
			if (alls.length > 0) onResetInvoices(true)
			onListInvoices(INVOICES_LIST)
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	/**
	 * onRefreshed.
	 * 
	 */
	onRefreshed = (list, selected = {}) => {
		try {
			//Get props
			let { onSetInvoices, onResetInvoices } = this.props

			//Reset state
			onResetInvoices(false)

			//Save filter
			onSetInvoices({ filtereds: list, filter: selected })
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	onPdfOpened = (pdfPath) => {
		try {
			window.open(pdfPath)
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	/**
	 * On Showed.
	 * 
	 * @param {*} id 
	 * @param {*} option 
	 */
	onShowed = (id, option) => {
		try {
			// Get props values
			let { alls, onResetInvoices, onSetInvoices } = this.props

			// Reset state
			onResetInvoices(false)

			// Get selected invoice item
			let selected = alls.find((item) => (item._id === id))

			// Set selected invoice item
			onSetInvoices({ invoice: { ...selected }, option: option })

			//Show modal
			if ((option === INVOICES_READ)) {
				this.onPdfOpened(selected.bill_path)
			} else {
				this.onToggled((
					(option === INVOICES_EDIT) ? "payVisible" : "cardVisible"
				), true)
			}
		} catch (error) {
			consoleErrorMessage(error)
		}
	}


	render() {
		const { t, alls, invoices, invoice, filter, loading, success, error, option } = this.props
		const { cardVisible, payVisible, readVisible } = this.state

		return (
			<React.Fragment>
				<Helmet>
					<title>{APP_NAME} | {t("Mes factures")}</title>
				</Helmet>

				<div className="page-content">
					<Container fluid>
						<Breadcrumbs
							title={t("Mes factures")}
							subtitle={(alls.length > 0) ? (`(${alls.length})`) : ""}
						/>

						<Row className="mb-2">
							{((error != "") && (option === INVOICES_LIST)) ? (
								<Col xl="12">
									<Alert color="danger" className="d-flex align-items-center justify-content-between m-0">
										{error} {" "}
										<Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
											Réessayer
										</Link>
									</Alert>
								</Col>
							) : (loading && (option === INVOICES_LIST)) ? (
								<Col xl="12">
									<Loader color="primary" />
								</Col>
							) : (
								<InvoiceList
									alls={alls}
									items={invoices}
									invoice={invoice}
									filter={filter}
									onShowed={this.onShowed}
									onRefreshed={this.onRefreshed}
									onReloaded={this.onLoaded}
									showReload
								/>
							)}
						</Row>
					</Container>
				</div>
				{(cardVisible) ? (
					<InvoiceCard
						opened={cardVisible}
						invoice={invoice}
						onClosed={this.onToggled}
					/>
				) : null}
				{(payVisible) ? (
					<InvoicePay
						opened={payVisible}
						onClosed={this.onToggled}
					/>
				) : null}
				{(readVisible) ? (
					<Reader
						opened={readVisible}
						link={invoice.bill_path}
						title="Contenu de la facture"
						onClosed={this.onToggled}
					/>
				) : null}
			</React.Fragment>
		)
	}

}
MyInvoices.propTypes = {
	t: PropTypes.any,
	history: PropTypes.any,
	error: PropTypes.string,
	option: PropTypes.string,
	loading: PropTypes.bool,
	success: PropTypes.bool,
	alls: PropTypes.array,
	invoices: PropTypes.array,
	invoice: PropTypes.object,
	filter: PropTypes.object,
	onListInvoices: PropTypes.func,
	onSetInvoices: PropTypes.func,
	onResetInvoices: PropTypes.func,
}
const mapStateToProps = state => ({
	alls: state.factures.invoices,
	invoices: state.factures.filtereds,
	invoice: state.factures.invoice,
	filter: state.factures.filter,
	error: state.factures.error,
	option: state.factures.option,
	success: state.factures.success,
	loading: state.factures.loading,
})
const mapDispatchToProps = dispatch => ({
	onSetInvoices: (data) => dispatch(setInvoices(data)),
	onResetInvoices: (all) => dispatch(resetInvoices(all)),
	onListInvoices: (option) => dispatch(listInvoices(option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(MyInvoices)))