import { all, fork } from "redux-saga/effects"

// Layout
import LayoutSaga from "./layout/saga"

// Auth
import AuthSaga from "./auth/saga"

// 
import OrderSaga from "./orders/saga"

// 
import InvoiceSaga from "./invoices/saga"

export default function* rootSaga() {
	yield all([
		//public
		fork(LayoutSaga),
		fork(AuthSaga),
		fork(OrderSaga),
		fork(InvoiceSaga),
	])
}
