// SELECT, INSERT, FILTER, FIND, GET   
export const ORDERS_GET = "ORDERS_GET"
export const ORDERS_FIND = "ORDERS_FIND"
export const ORDERS_SELECT = "ORDERS_SELECT"
export const ORDERS_INSERT = "ORDERS_INSERT"
export const ORDERS_CALCULATE = "ORDERS_CALCULATE"

// ERROR, SUCCESS, SET, RESET
export const ORDERS_SET = "ORDERS_SET"
export const ORDERS_RESET = "ORDERS_RESET"
export const ORDERS_ERROR = "ORDERS_ERROR"
export const ORDERS_SUCCESS = "ORDERS_SUCCESS"