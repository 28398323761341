import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap"

// import moment
import moment from 'moment'
import 'moment/locale/fr'


class InvoiceCard extends React.Component {

    render() {
        const { opened, classes, onClosed, invoice } = this.props
        const { _id, bill_reference, bill_price, payment_info, bill_status, created_at, payment_at } = invoice

        return (
            <React.Fragment>
                <Modal isOpen={opened} centered={true} className={classes}>
                    <ModalHeader tag="h4">
                        Détails de paiement d'une facture
                        {(bill_reference && created_at) ? (
                            <span className="d-block fs-12 text-secondary">
                                Facture {" "}
                                <strong> #{bill_reference} </strong>
                                du {" "}
                                <strong> {`${moment.unix(created_at).format('DD MMM YYYY')}`} </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xl="12">
                                {(_id && bill_reference) ? (
                                    <Row>
                                        <Col xl="12" className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>
                                                    <small className="d-block text-muted">
                                                        <i className="bx bx-money me-1" />
                                                        Montant
                                                    </small>
                                                    <strong className="fs-17">{bill_price}</strong>(XOF)
                                                </span>
                                                <span>
                                                    <small className="d-block text-muted">
                                                        <i className="bx bxs-calendar me-1" />
                                                        Payée le
                                                    </small>
                                                    <strong className="fs-15">
                                                        {`${moment.unix(payment_at).format('DD MMM YYYY')}`}
                                                    </strong>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col xl="12">
                                            <div className="p-2 bg-light rounded">
                                                <p className="m-0">
                                                    {payment_info || "RAS"}
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                ) : (
                                    <p className="p-2 mb-0">
                                        La facture n'a pas été retrouvée. Aucun élément à afficher.
                                    </p>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            data-dismiss="modal"
                            className="btn btn-light"
                            onClick={() => onClosed("cardVisible", false)}
                        >
                            Fermer
                        </button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

}
InvoiceCard.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    onClosed: PropTypes.func,
    invoice: PropTypes.object,
}
InvoiceCard.defaultProps = {
    opened: false,
    classes: "invoice-card-modal",
    invoice: {}
}
export default InvoiceCard