import {
    ORDERS_GET, ORDERS_INSERT, ORDERS_CALCULATE, ORDERS_SELECT, ORDERS_FIND, ORDERS_SUCCESS, ORDERS_ERROR, ORDERS_RESET, ORDERS_SET
} from "./constants"


export const listOrders = (option) => ({
    type: ORDERS_SELECT,
    payload: { option },
})

export const showOrder = (id, extra, option) => ({
    type: ORDERS_GET,
    payload: { id, extra, type: "id", option },
})

export const addOrder = (data, option) => ({
    type: ORDERS_INSERT,
    payload: { data, option },
})

export const evaluateOrder = (data, option) => ({
    type: ORDERS_CALCULATE,
    payload: { data, option },
})

export const searchOrders = (data, option) => ({
    type: (data.customer_phone) ? ORDERS_FIND : ORDERS_GET,
    payload: { data, type: "code", option },
})

export const successOrders = (data, option) => ({
    type: ORDERS_SUCCESS,
    payload: { data, option },
})

export const errorOrders = (error = "", option = "") => ({
    type: ORDERS_ERROR,
    payload: { error, option },
})

export const setOrders = (data) => ({
    type: ORDERS_SET,
    payload: { ...data },
})

export const resetOrders = (all) => ({
    type: ORDERS_RESET,
    payload: { all },
})