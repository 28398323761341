// @flow
import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_TOPBAR_THEME,
	CHANGE_PRELOADER,
} from "./constants"

const INIT_STATE = {
	layoutTheme: "light",
	layoutType: "vertical",
	layoutWidth: "fluid",
	sidebarTheme: "dark",
	sidebarThemeImage: "none",
	sidebarType: "default",
	topbarTheme: "light",
	isPreloader: true,
	isMobile: false,
}

const ground = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LAYOUT:
			return {
				...state,
				layoutType: action.payload,
			}
		case CHANGE_PRELOADER:
			return {
				...state,
				isPreloader: action.payload,
			}

		case CHANGE_LAYOUT_WIDTH:
			return {
				...state,
				layoutWidth: action.payload,
			}
		case CHANGE_TOPBAR_THEME:
			return {
				...state,
				topbarTheme: action.payload,
			}
		default:
			return state
	}
}

export default ground