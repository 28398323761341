import React from "react"
import PropTypes from 'prop-types'

// import components
import { Row, Col, Alert, Input, Label, Card, CardBody, CardTitle, CardText, CardHeader } from "reactstrap"

// import form components
import { AvForm, AvField } from "availity-reactstrap-validation"

// Form Mask
import InputMask from "react-input-mask"

// import common custom components
import DateRanger from "components/common/DateRanger"


const OrderSearch = React.memo(({ query, onChanged, onRanged, onRetrieved, onResearched, withReset, onReseted }) => {

    return (
        <React.Fragment>
            <Card className="shadow-none">
                <CardHeader className="bg-white">
                    <h4>
                        Filtres
                        {onReseted ? (
                            <small
                                className="d-block text-muted fs-10 fw-bolder text-decoration-underline h-cursor"
                                onClick={() => onReseted()}
                            >
                                Effacer les filtres
                            </small>
                        ) : null}
                    </h4>
                </CardHeader>
                <CardBody>
                    <Col sm="12" className="mb-5">
                        <Row>
                            <Col sm={12} className="mb-2">
                                <label htmlFor="code" className="form-label">
                                    Code
                                </label>
                                <input
                                    id="code" name="code" type="text"
                                    value={query.code || ""}
                                    className="form-control"
                                    onChange={(e) => onChanged(e)}
                                />
                            </Col>
                            <Col sm={12}>
                                <div className="d-flex align-items-end justify-content-end">
                                    {(withReset && onReseted) ? (
                                        <button
                                            type="button" className="btn btn-outline-primary px-2 me-1"
                                            onClick={() => onReseted("code")}
                                        >
                                            <i className='bx bxs-trash' />
                                        </button>
                                    ) : null}
                                    <button
                                        type="button" className="btn btn-primary px-2 ms-1"
                                        onClick={() => onRetrieved()}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="bx bx-search-alt bx-xs text-white me-1" />{" "}
                                            Rechercher
                                        </div>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm="12" className="mb-5">
                        <Row>
                            <Col sm={12} className="mb-2">
                                <label htmlFor="period" className="form-label">
                                    Date/Période
                                </label>
                                <div className="form-group">
                                    <DateRanger
                                        start={query.start}
                                        end={query.end}
                                        onRanged={onRanged}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="d-flex align-items-end justify-content-end">
                                    {(withReset && onReseted) ? (
                                        <button
                                            type="button" className="btn btn-outline-primary px-2 me-1"
                                            onClick={() => onReseted("date")}
                                        >
                                            <i className='bx bxs-trash' />
                                        </button>
                                    ) : null}
                                    <button
                                        type="submit" className="btn btn-primary px-2"
                                        onClick={() => onResearched()}
                                    >
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="bx bx-search-alt bx-xs text-white me-1" />{" "}
                                            Rechercher
                                        </div>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </CardBody>
            </Card>
        </React.Fragment>
    );

});
OrderSearch.propTypes = {
    query: PropTypes.object,
    withReset: PropTypes.bool,
    onRanged: PropTypes.func,
    onChanged: PropTypes.func,
    onRetrieved: PropTypes.func,
    onResearched: PropTypes.func,
    onReseted: PropTypes.func
}
OrderSearch.defaultProps = {
    query: {},
    withReset: false
}
export default OrderSearch