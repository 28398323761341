import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const Reader = ({ opened, classes, size, link, title, onClosed }) => {

    return (
        <React.Fragment>
            <Modal isOpen={opened} centered={true} className={classes} size={size}>
                <ModalHeader tag="h4">
                    {title}
                </ModalHeader>
                <ModalBody>
                    <center>
                        {(link && (link !== "")) ? (
                            <embed
                                src={link}
                                type="application/pdf"
                                height={500}
                                width={"100%"}
                            />
                        ) : (
                            <p className="m-0 p-2">
                                Aucun document à afficher
                            </p>
                        )}
                    </center>
                </ModalBody>
                <ModalFooter>
                    <button
                        type="button" data-dismiss="modal" className="btn btn-light"
                        onClick={() => onClosed("readVisible", false)}
                    >
                        Fermer
                    </button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )

}
Reader.propTypes = {
    opened: PropTypes.bool,
    size: PropTypes.string,
    classes: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string,
    onClosed: PropTypes.func
}
Reader.defaultProps = {
    opened: false,
    size: "lg",
    classes: "reader-modal",
    link: "",
    title: "Contenu d'un document"
}
export default Reader