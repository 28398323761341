// HTTPS
export const ERROR_409 = { code: 409, label: "Conflict", message: "Requête avec des conflits." }
export const ERROR_404 = { code: 404, label: "Not Found", message: "Ressource non retrouvée." }
export const ERROR_401 = { code: 401, label: "Unauthorized", message: "Requête non autorisée." }
export const ERROR_400 = { code: 400, label: "Bad Request", message: "Requête invalide." }
export const ERROR_403 = { code: 403, label: "Forbidden", message: "Accès impossible." }
export const ERROR_500 = { code: 500, label: "Internal Server Error", message: "Erreur interne du serveur." }

// UNKNOWN
export const UNKNOWN_ERROR = { message: "Une erreur est survenue. Veuillez réessayer." }

// NETWORK
export const NETWORK_ERROR = { label: "Network Error", message: "Une erreur est survenue. Veuillez vérifier votre connexion internet et réessayer." }

// DUPLICATES ERRORS
export const DUPLICATE_ERROR = { label: "duplicate key error collection" }

// LOGIN PASSWORD
export const PASSWORD_ERROR = "Password doesn't match"

/**
 * Get error.
 * 
 * @param {*} status 
 * @param {*} statusText 
 * @returns 
 */
const getErrorByStatus = (status) => {
    switch (status) {
        case ERROR_400.code:
            return ERROR_400;
        case ERROR_401.code:
            return ERROR_401;
        case ERROR_403.code:
            return ERROR_403;
        case ERROR_404.code:
            return ERROR_404;
        case ERROR_409.code:
            return ERROR_409;
        case ERROR_500.code:
            return ERROR_500;
        default:
            return UNKNOWN_ERROR;
    }
}

/**
 * Conflicted error.
 * 
 * @param {*} errCode 
 * @param {*} errMessage 
 * @returns 
 */
const isConflictedError = (errCode, errMessage) => {
    return (
        (errCode && (errCode === ERROR_409.code)) ||
        (errMessage && errMessage.includes(DUPLICATE_ERROR.label))
    )
}

/**
 * Unauthorized error.
 * 
 * @param {*} error 
 * @returns 
 */
const isUnauthorizedError = (error) => {
    return (error.toLowerCase() === ERROR_401.message.toLowerCase())
}

/**
 * Format error message.
 * 
 * @param {*} errorObject 
 * @param {*} messages 
 * @returns 
 */
export const errorMessage = (errorObject, messages = {}) => {
    let error = errorObject.response ? errorObject.response : errorObject
    let msgError = ""
    if (error && error.data && error.data.status) {
        let sError = getErrorByStatus(error.data.status)
        msgError = (messages[sError.code] ? messages[sError.code] : sError.message)
    } else if (error && error.message && (error.message === NETWORK_ERROR.label)) {
        msgError = NETWORK_ERROR.message
    } else {
        msgError = UNKNOWN_ERROR.message
    }
    return { unauth: isUnauthorizedError(msgError), message: msgError }
}