import { combineReducers } from "redux"

// Layout
import ground from "./layout/reducer"

// Auth
import auth from "./auth/reducer"

// Orders
import commandes from "./orders/reducer"

// Invoices
import factures from "./invoices/reducer"

const rootReducer = combineReducers({
	// public
	ground, auth, commandes, factures
})

export default rootReducer
