import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Alert, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { resetOrders, setOrders, showOrder } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import scroll bar
import SimpleBar from "simplebar-react"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Loader from 'components/common/Loader'

// import constants
import {
    EVENTS_FORWARD, EVENTS_TREATMENT, ORDERS_COMPLETED, ORDERS_SHOW, USER_AGENCY
} from 'helpers/constants'

// import utilities
import {
    copyTextToClipboard, formatPhoneNumberForDisplay, getConstantByKey, consoleErrorMessage
} from 'helpers/utilities'

class OrderCard extends React.Component {

    constructor(props) {
        super(props)

        this.refDiv = React.createRef()

        this.state = {
            isCopied: false
        }
    }

    componentDidMount() {
        try {//Load order
            this.onLoaded()
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            let { onSetOrders, onResetOrders } = this.props
            onResetOrders(false)
            onSetOrders({ order: {} })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On loaded.
     * 
     */
    onLoaded = () => {
        try {
            //Get props
            let { order, onShowOrder } = this.props

            //Load order details
            onShowOrder(order._id, order.delivery_auth, ORDERS_SHOW)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Calculate current status duration.
     * 
     * @returns string
     */
    onDurationCalculated() {
        try {
            let { events, current_status } = this.props.order
            let status_event_arr = events.filter((item) => (item.event === EVENTS_TREATMENT.key) && (item.status === current_status))
            return ((status_event_arr[0] && status_event_arr[0].at) ? moment.unix(status_event_arr[0].at).fromNow() : "")
        } catch (error) {
            consoleErrorMessage(error)
            return ""
        }
    }

    /**
     * on copy clicked.
     * 
     * @param {*} copyText 
     */
    onCopyClicked = (copyText) => {
        copyTextToClipboard(copyText).then(() => {
            this.setState({ isCopied: true });
            setTimeout(() => {
                this.setState({ isCopied: false });
            }, 1500);
        }).catch((error) => {
            consoleErrorMessage(error)
        });
    }


    render() {
        const { isCopied } = this.state
        const { opened, classes, fullscreen, size, option, error, loading, order, onClosed, onEnlarged } = this.props
        const {
            _id, code, start_point, delivery_point, round_trip, created_at, completed_at, received_at, delivery_auth,
            nature, description_file, description_text, refund, payment, current_status, reported, incidents, events,
            current_user, current_user_id, customer_id, customer, receiver
        } = order

        let order_status = (order && order.current_status) ? getConstantByKey(order.current_status) : {}
        let order_nature = (order && order.nature) ? getConstantByKey(order.nature) : {}
        let order_history = (order && order.events) ? order.events.map((item) => ({ ...item, type: getConstantByKey(item.event) })) : []

        let order_refunded = (refund && refund.status && refund.at && (refund.at !== 0) && refund.reason)
        let order_completed = (current_status && (current_status === ORDERS_COMPLETED.key) && completed_at && (completed_at !== 0))

        return (
            <React.Fragment>
                <Modal
                    isOpen={opened} centered={true}
                    className={`order-card ${classes}${fullscreen ? " modal-fullscreen" : ""}`}
                    size={`${fullscreen ? "xl" : size}`}
                >
                    <ModalHeader tag="h4" className="order-header">
                        <span className="order-title">
                            Détails d'une course
                            {(order && order._id && order.code) ? (
                                <span className="d-block fs-12 text-secondary">
                                    Course {" "}
                                    <strong> #{code} </strong>
                                    du {" "} 
                                    <strong> {`${moment.unix(created_at).format('DD MMM YYYY')}`} </strong>
                                    à {" "}
                                    <strong> {moment.unix(created_at).format("HH:mm")} </strong>
                                    {order_refunded ? (
                                        <span className="d-block fw-bold text-danger">
                                            Remboursée
                                        </span>
                                    ) : null}
                                </span>
                            ) : null}
                        </span>
                        {(loading && (option === ORDERS_SHOW)) ? null : (
                            <span className="toolbar button-items">
                                <button
                                    type="button"
                                    className="btn btn-outline-light btn-sm mx-1 center-ibox"
                                    onClick={() => this.onLoaded()}
                                >
                                    Actualiser
                                    <i className="bx bx-reset ms-1" />
                                </button>
                            </span>
                        )}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="m-1 g-1">
                            <Col xl="12">
                                {(loading && (option === ORDERS_SHOW)) ? (
                                    <Loader color="primary" />
                                ) : ((error !== "") && (option === ORDERS_SHOW)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between mb-0">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (order && order._id && order.code) ? (
                                    <Row className="order-details g-2">
                                        <Col xl="3" sm="12" className="my-1 my-lg-0">
                                            <div className="border border-light rounded">
                                                <SimpleBar style={{ maxHeight: "500px" }} ref={this.refDiv}>
                                                    <div className="p-3">
                                                        {(order_history && (order_history.length > 0)) ? (
                                                            <ul className="verti-timeline list-unstyled m-0">
                                                                {order_history.map((item, index) => (
                                                                    <li key={`order_event_${index}`} className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            {((item.event === EVENTS_TREATMENT.key) && (item.status === ORDERS_COMPLETED.key)) ? (
                                                                                <i className="bx bxs-check-circle fs-18 text-success" />
                                                                            ) : (
                                                                                <i className={`bx bxs-${item.type.icon}-circle fs-18 text-${item.type.color}`} />
                                                                            )}
                                                                        </div>
                                                                        <span className="d-block fs-13 text-dark">
                                                                            {(item.status) ? getConstantByKey(item.status, "name") : (item.type) ? item.type.name : ""}
                                                                        </span>
                                                                        {item.user ? (
                                                                            <span className="d-block fs-12 text-muted orders-icons">
                                                                                <small className="fst-light me-1">
                                                                                    {(item.event === EVENTS_FORWARD.key) ? "à" : "par"}
                                                                                </small>
                                                                                {item.user.name ? item.user.name : (`${item.user.firstname} ${item.user.lastname}`)}
                                                                                <span
                                                                                    className="ic-event ms-1"
                                                                                    title={`${(item.user.role === USER_AGENCY.key) ? "Agence" : "Facteur"}`}
                                                                                >
                                                                                    <i className={`bx bxs-${(item.user.role === USER_AGENCY.key) ? "store" : "user"}`} />
                                                                                </span>
                                                                            </span>
                                                                        ) : null}
                                                                        <span className="d-block fs-11 text-muted">
                                                                            {moment.unix(item.at).format("DD MMM YYYY à HH:mm")}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p className="m-0 p-2">
                                                                Aucun suivi à afficher
                                                            </p>
                                                        )}
                                                    </div>
                                                </SimpleBar>
                                            </div>
                                        </Col>

                                        <Col xl="6" sm="12" className="my-1 my-lg-0">
                                            <Row>
                                                <Col sm="12">
                                                    {(description_file !== "") ? (
                                                        <audio controls="controls" preload="metadata" className="m-0 w-100 rounded">
                                                            <source src={description_file} type="audio/mpeg" />
                                                            Votre navigateur ne prend pas en charge l'élément <code>audio</code>.
                                                        </audio>
                                                    ) : (description_text !== "") ? (
                                                        <div className="orders-points rounded bg-light p-3 mb-2">
                                                            <span className="d-flex align-items-center mb-1">
                                                                <span className="points-box description-point me-1">
                                                                    <i className="bx bxs-map text-muted" />
                                                                </span>
                                                                <small className="text-muted fw-bolder">Description</small>
                                                            </span>
                                                            <p className="m-0 p-0">
                                                                {description_text}
                                                            </p>
                                                        </div>
                                                    ) : null}
                                                    <div className="bg-section rounded p-3">
                                                        <Row className="g-0">
                                                            <Col xl="6" sm="12" className="my-1 my-lg-0">
                                                                <div className="orders-points pe-1">
                                                                    <span className="d-flex align-items-center mb-1">
                                                                        <span className="points-box start-point me-1">
                                                                            <i className="bx bxs-map text-orange" />
                                                                        </span>
                                                                        <small className="text-muted fw-bolder">Enlèvement</small>
                                                                    </span>
                                                                    <span className="d-block fs-12 fw-bolder">
                                                                        {formatPhoneNumberForDisplay(start_point.contact)}
                                                                    </span>
                                                                    <span className="d-block fs-13 text-dark">
                                                                        {start_point.address}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col xl="6" sm="12" className="my-1 my-lg-0">
                                                                <div className="orders-points ps-1">
                                                                    <span className="d-flex align-items-center mb-1">
                                                                        <span className="points-box end-point me-1">
                                                                            <i className="bx bxs-map text-blue" />
                                                                        </span>
                                                                        <small className="text-muted fw-bolder">Livraison</small>
                                                                    </span>
                                                                    <span className="d-block fs-12 fw-bolder">
                                                                        {formatPhoneNumberForDisplay(delivery_point.contact)}
                                                                    </span>
                                                                    <span className="d-block fs-13 text-dark">
                                                                        {delivery_point.address}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>

                                                {order_refunded ? (
                                                    <Col sm="12">
                                                        <div className="border border-danger rounded p-3">
                                                            Course remboursée le {" "}
                                                            <strong> {`${moment.unix(refund.at).format('DD MMM YYYY')}`} </strong> {" "}
                                                            à {" "} <strong> {`${moment.unix(refund.at).format('HH:mm:ss')}`} </strong>
                                                            <span className="d-block mt-1">
                                                                <small className="d-block text-muted">au motif de</small>
                                                                {refund.reason}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Col>

                                        <Col xl="3" sm="12" className="my-1 my-lg-0">
                                            <Row>
                                                <Col sm="12">
                                                    <div className={`bg-soft bg-${order_status.color} rounded p-3`}>
                                                        <span className={`d-block fw-bolder fs-15 text-${order_status.color}`}>
                                                            {order_status.name}
                                                        </span>
                                                        <small className="d-block fw-bold text-muted fst-italic">
                                                            {this.onDurationCalculated()}
                                                        </small>
                                                    </div>
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    <div className="bg-section rounded p-3">
                                                        <span className="d-flex align-items-center justify-content-between fs-14">
                                                            <span className="fw-bold fs-20 text-muted">{payment.price}<small>(XOF)</small></span>
                                                            <i className={`bx bx-money bx-xs text-muted`} />
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    <div className={`bg-soft bg-${order_nature.color} rounded p-3`}>
                                                        <span className={`d-flex align-items-center justify-content-between fs-14 fw-bold text-${order_nature.color}`}>
                                                            {order_nature.name}
                                                            <i className={`bx ${order_nature.icon} bx-xs text-${order_nature.color}`} />
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    {(delivery_auth && delivery_auth.enabled) ? (
                                                        <div className="bg-soft bg-blue rounded p-3">
                                                            <span className="text-blue d-flex align-items-center justify-content-between">
                                                                Double confirmation demandée
                                                                <i className='bx bx-check-double bx-xs text-blue' />
                                                            </span>
                                                            <span
                                                                className="d-block fw-bolder text-blue fs-3 mt-1 h-cursor"
                                                                onClick={() => this.onCopyClicked(delivery_auth.code)}
                                                                title="Cliquer pour copier"
                                                            >
                                                                <span className="text-decoration-underline">
                                                                    {delivery_auth.code}
                                                                </span>
                                                                {isCopied ? (
                                                                    <small className="d-block text-muted fs-10">
                                                                        *Code de livraison copié avec succès
                                                                    </small>
                                                                ) : null}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-soft bg-secondary rounded p-3">
                                                            <span className="text-muted d-flex align-items-center justify-content-between">
                                                                Pas de double confirmation
                                                                <i className='bx bx-check-double bx-xs text-muted' />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    {(round_trip) ? (
                                                        <div className="bg-soft bg-blue rounded p-3">
                                                            <span className="text-blue d-flex align-items-center justify-content-between">
                                                                Aller-retour
                                                                <i className='bx bx-transfer bx-xs text-blue' />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-soft bg-secondary rounded p-3">
                                                            <span className="text-muted d-flex align-items-center justify-content-between">
                                                                Pas d'aller-retour
                                                                <i className='bx bx-transfer bx-xs text-muted' />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Col>

                                                {(receiver && receiver.name && (receiver.name !== "")) ? (
                                                    <Col sm="12" className="mt-2">
                                                        <div className="bg-section rounded p-3">
                                                            <div className="d-flex align-items-center justify-content-between orders-thumbnails">
                                                                <address className="mb-0">
                                                                    <small> Destinataire </small>
                                                                    <span className="d-block fs-13 fw-bold mt-1">
                                                                        {formatPhoneNumberForDisplay(receiver.phone)}
                                                                    </span>
                                                                    <span className="d-block text-dark fs-14 fw-bolder">
                                                                        {receiver.name}
                                                                    </span>
                                                                </address>
                                                                {(order_completed && receiver.signature && (receiver.signature !== "")) ? (
                                                                    <img
                                                                        alt="signature"
                                                                        className="signature-thumbnail"
                                                                        src={receiver.signature}
                                                                        onClick={() => {
                                                                            if (onEnlarged) onEnlarged(true, receiver.signature, `Accusé de réception, course #${code}`)
                                                                        }}
                                                                    />
                                                                ) : (order_completed && receiver.in_agency) ? (
                                                                    <span className="agency-thumbnail" title="Récupérée en agence">
                                                                        <i className={`bx bxs-store text-${order_status.color}`} />
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                            <a
                                                                href={`accuse-de-reception/${_id}`}
                                                                className="text-decoration-underline text-muted"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Accusé de réception
                                                            </a>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Alert color="info"> Aucune course retrouvée </Alert>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button" data-dismiss="modal" className="btn btn-light"
                            onClick={() => onClosed("cardVisible", false)}
                        >
                            Fermer
                        </button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

}
OrderCard.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    opened: PropTypes.bool,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    order: PropTypes.object,
    onClosed: PropTypes.func,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
    onShowOrder: PropTypes.func,
}
OrderCard.defaultProps = {
    opened: false,
    classes: "order-card-modal",
    fullscreen: false,
    size: "xl"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onShowOrder: (id, extra, option) => dispatch(showOrder(id, extra, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(OrderCard)))
