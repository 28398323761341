import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_TOPBAR_THEME,
	CHANGE_PRELOADER,
} from "./constants"

export const changeLayout = layout => ({
	type: CHANGE_LAYOUT,
	payload: layout,
})

export const changePreloader = layout => ({
	type: CHANGE_PRELOADER,
	payload: layout,
})

export const changeLayoutWidth = width => ({
	type: CHANGE_LAYOUT_WIDTH,
	payload: width,
})

export const changeTopbarTheme = topbarTheme => ({
	type: CHANGE_TOPBAR_THEME,
	payload: topbarTheme,
})
