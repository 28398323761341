import { takeEvery, put, call } from "redux-saga/effects"

// import services
import { generateSecret, login, logout, updatePassword, validateSession } from "services/authService"

// import actions
import { authError, authSuccess, isUnauthorized } from "./actions"

// import redux constants
import { AUTH_SECRET, AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE, AUTH_SESSION } from "./constants"

// import constants
import { CUSTOMER_KEY } from "helpers/constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { formatUserWithName, isSuccessfulRequest, consoleErrorMessage } from "helpers/utilities"

// Login errors messages
const AUTH_ERRORS = {
	"LOGIN": {
		400: "Identifiants de connexion invalides.",
		401: "L'identifant/le mot de passe n'existe pas.",
		404: "L'identifant/le mot de passe n'existe pas.",
		403: "L'utilisateur est désactivé."
	},
	"LOGOUT": {},
	"UPDATE": {
		403: "L'ancien mot de passe ne correspond pas."
	},
	"GENERATE_SECRET": {
		403: "Vous n'etes pas autorisé à effectuer cette opération."
	}
}

function* onLoginUser({ payload }) {
	try {
		const response = yield call(login, payload.data)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			let logged = formatUserWithName(response.data)
			localStorage.setItem(CUSTOMER_KEY, JSON.stringify(logged))
			yield put(authSuccess())
		} else {
			consoleErrorMessage(response, "LOGIN ERROR")
			yield put(authError(errorMessage(response, AUTH_ERRORS.LOGIN).message))
		}
	} catch (error) {
		consoleErrorMessage(error, "LOGIN ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onLogoutUser() {
	try {
		const response = yield call(logout)
		let { unauth, message } = errorMessage(response, AUTH_ERRORS.LOGOUT)
		if (isSuccessfulRequest(response.status, response.statusText) || unauth) {
			yield put(authSuccess())
		} else {
			consoleErrorMessage(response, "LOGOUT ERROR")
			yield put(authError(message))
		}
	} catch (error) {
		consoleErrorMessage(error, "LOGOUT ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onValidateSession() {
	try {
		const response = yield call(validateSession)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			let logged = formatUserWithName(response.data)
			localStorage.setItem(CUSTOMER_KEY, JSON.stringify(logged))
			yield put(authSuccess())
		} else {
			consoleErrorMessage(response, "VALIDATE SESSION ERROR")
			yield put(isUnauthorized())
		}
	} catch (error) {
		consoleErrorMessage(error, "VALIDATE SESSION ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onUpdatePassword({ payload }) {
	try {
		const response = yield call(updatePassword, payload.data)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			const loggedOut = yield call(logout)
			yield put(authSuccess())
			if (!isSuccessfulRequest(loggedOut.status, loggedOut.statusText)) {
				consoleErrorMessage(loggedOut, "LOGOUT ERROR")
			}
		} else {
			consoleErrorMessage(response, "UPDATE PASSWORD ERROR")
			let { unauth, message } = errorMessage(response, AUTH_ERRORS.UPDATE)
			yield put(authError((unauth ? "" : message)))
			if (unauth) yield put(isUnauthorized())
		}
	} catch (error) {
		consoleErrorMessage(error, "UPDATE PASSWORD ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* onGenerateSecret() {
	try {
		const response = yield call(generateSecret)
		let { unauth, message } = errorMessage(response, AUTH_ERRORS.GENERATE_SECRET)
		if (isSuccessfulRequest(response.status, response.statusText)) {
			yield put(authSuccess(response.data.client_secret))
		} else {
			consoleErrorMessage(response, "GENERATE SECRET ERROR")
			yield put(authError((unauth ? "" : message)))
			if (unauth) yield put(isUnauthorized())
		}
	} catch (error) {
		consoleErrorMessage(error, "GENERATE SECRET ERROR")
		yield put(authError(errorMessage(error).message))
	}
}

function* AuthSaga() {
	yield takeEvery(AUTH_LOGIN, onLoginUser)
	yield takeEvery(AUTH_LOGOUT, onLogoutUser)
	yield takeEvery(AUTH_UPDATE, onUpdatePassword)
	yield takeEvery(AUTH_SECRET, onGenerateSecret)
	yield takeEvery(AUTH_SESSION, onValidateSession)
}

export default AuthSaga