import API, { ORDER_BASE_URL } from './baseService';

export function selectOrders() {
    return API.get(`${ORDER_BASE_URL}orders/customer`).then((response) => response).catch((error) => error);
}

export function getOrder(order, option = "id") {
    let get_option = ((option === "id") ? "detail" : "code")
    return API.get(`${ORDER_BASE_URL}orders/${get_option}/${order}`).then((response) => response).catch((error) => error);
}

export function insertOrder(data) {
    let customConfigs = { headers: { 'content-type': 'multipart/form-data' } }
    return API.post(`${ORDER_BASE_URL}orders/new`, data, customConfigs).then((response) => response).catch((error) => error);
}

export function createOrder(data) {
    let customConfigs = { headers: { 'content-type': 'multipart/form-data' } }
    return API.post(`${ORDER_BASE_URL}orders/create`, data, customConfigs).then((response) => response).catch((error) => error);
}

export function findOrder(started_at, ended_at, phone, partner) {
    return API.get(`${ORDER_BASE_URL}orders/find?start_date=${started_at}&end_date=${ended_at}&customer_phone=${phone}&partner=${partner}`).then((response) => response).catch((error) => error);
}

export function calculateOrder({ s_lat, s_lng, d_lat, d_lng, rt }) {
    return API.get(`${ORDER_BASE_URL}prices/of?s_lat=${s_lat}&s_lng=${s_lng}&d_lat=${d_lat}&d_lng=${d_lng}&rt=${rt}`).then((response) => response).catch((error) => error);
}

