import React from 'react'
import PropTypes from 'prop-types'

// import components
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalBody, ModalFooter, ModalHeader, Alert, Label,
} from "reactstrap"

// import form components
import {
    AvForm, AvGroup, AvInput, AvFeedback
} from "availity-reactstrap-validation"

// import router, link
import { withRouter } from "react-router-dom"

//import i18n
import { withTranslation } from "react-i18next"

// import Redux
import { connect } from "react-redux"

// import deep-equal
import equal from "deep-equal"

// import toaster
import { toast } from 'react-toastify'

// inport Loader
import Loader from '../common/Loader'

// import constants
import { CLIPBOARD_MSG_DURATION, CUSTOMER_KEY, USER_LOGOUT, USER_SESSION } from "helpers/constants"

// import actions
import { authLogout, authReset, authUpdate, authGenerateSecret } from 'store/actions'

// import utilities
import { copyTextToClipboard, formatPhoneNumberForDisplay, consoleErrorMessage, formatCustomUserName } from 'helpers/utilities'


class Profile extends React.Component {

    constructor(props) {
        super(props)

        // init state
        this.state = {
            drop: false,
            logoutVisible: false,
            profileVisible: false,
            passwordVisible: false,
            devModeVisible: false,
            username: "",
            isDevModeEnabled: false,
            isCopied: false,
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmedPassword: false,
            passwords: {
                new_password: "",
                confirmed_password: "",
                same_password: false
            }
        }
    }

    componentDidMount() {
        try {
            //Reset state
            this.props.onReset(true)

            //Extract user data
            let { firstname, lastname, name, dev_options } = JSON.parse(localStorage.getItem(CUSTOMER_KEY))

            //Save username & isDevModeEnabled
            this.setState({
                username: formatCustomUserName(firstname, lastname, name),
                isDevModeEnabled: dev_options.enabled
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {
            if ((!equal(prevProps, this.props))) {
                const { unauthorized, success, history, option } = this.props
    
                if (unauthorized) {//token expired
                    toast.info(
                        "Votre session a expirée. Vous devez vous reconnecter.",
                        {
                            onClose: () => {
                                localStorage.removeItem(CUSTOMER_KEY)
                                history.push("/login")
                            }
                        }
                    )
                }
    
                if (success && option === USER_LOGOUT) {//logout successfully
                    toast.success(
                        "Vous êtes déconnecté ! Vous pouvez quitter votre espace de travail.",
                        {
                            onClose: () => {
                                localStorage.removeItem(CUSTOMER_KEY)
                                history.push("/login")
                            }
                        }
                    )
                }
    
                if (success && option === USER_SESSION) {//update user data after session validation
                    //Extract user data
                    let { firstname, lastname, name, dev_options } = JSON.parse(localStorage.getItem(CUSTOMER_KEY))
    
                    //Update username isDevModeEnabled
                    this.setState({
                        username: formatCustomUserName(firstname, lastname, name),
                        isDevModeEnabled: dev_options.enabled
                    })
                }
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

     /**
     * on copy clicked.
     * 
     * @param {*} copyText 
     */
    onCopyClicked = (copyText) => {
        copyTextToClipboard(copyText).then(() => {
            this.setState({ isCopied: true });
            setTimeout(() => {
                this.setState({ isCopied: false });
            }, CLIPBOARD_MSG_DURATION);
        }).catch((error) => {
            consoleErrorMessage(error)
        });
    }

    /**
     * Drop toggle.
     * 
     */
    onDropToggled = () => {
        this.setState(prevState => ({
            drop: !prevState.drop,
        }))
    }

    /**
     * Modal toggle.
     * 
     */
    onModalToggled = (option = "logoutVisible") => {
        if (!this.state[option]) {
            this.props.onReset(false)
        }
        this.setState(prevState => ({
            [option]: !prevState[option],
        }))
    }

    /**
     * On password toggled.
     * 
     */
    onPasswordToggled = (option = "showCurrentPassword") => {
        this.setState(prevState => ({
            [option]: !prevState[option],
        }))
    }

    /**
     * On submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();

        try {//Launch password updates
            let { onUpdate } = this.props

            onUpdate({
                new_password: values.new_password.trim(),
                password: values.current_password.trim()
            }, USER_LOGOUT)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
    * On changed.
    * 
    * @param {*} event 
    */
    onChanged = (event) => {
        try {
            //Get target
            let { checked, value, name, type } = event.target
            let { confirmed_password, new_password, same_password } = this.state.passwords
            let areSame = same_password

            if (["confirmed_password", "new_password"].includes(name)) {
                let toCompare = ((name === "confirmed_password") ? new_password : confirmed_password)
                areSame = (value === toCompare)
            }

            //Store values
            this.setState({
                passwords: {
                    ...this.state.passwords,
                    [name]: value,
                    same_password: areSame
                }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Show logout modal.
     * 
     * @returns 
     */
    onLogoutOpened() {
        let { logoutVisible } = this.state
        if (logoutVisible) {
            const { error, loading, success, onLogout } = this.props
            return (
                <Modal isOpen={logoutVisible} role="dialog" autoFocus={true} centered={true} className="logout-modal" tabIndex="-1">
                    <ModalHeader tag="h4"> Déconnexion </ModalHeader>
                    <ModalBody>
                        {(error && error != "") ? (
                            <Alert color="danger"> {error} </Alert>
                        ) : null}

                        <h5 className="mt-2 text-center px-3">
                            Voulez-vous vous déconnecter de votre espace de travail ?
                        </h5>
                    </ModalBody>
                    <ModalFooter>
                        {loading ? (
                            <Loader color="danger" />
                        ) : success ? (
                            <Alert color="info">Redirection...</Alert>
                        ) : (
                            <>
                                <button
                                    type="button" data-dismiss="modal" className="btn btn-light"
                                    onClick={() => this.onModalToggled("logoutVisible")}
                                >
                                    Non
                                </button>
                                <button
                                    type="button" className="btn btn-danger" onClick={() => onLogout(USER_LOGOUT)}
                                >
                                    Oui
                                </button>
                            </>
                        )}
                    </ModalFooter>
                </Modal>
            );
        }
    }

    /**
     * Show profile modal.
     * 
     * @returns 
     */
    onProfileOpened() {
        let { profileVisible } = this.state
        if (profileVisible && localStorage.getItem(CUSTOMER_KEY)) {
            let { firstname, lastname, name, email, phone, address, username, avatar } = JSON.parse(localStorage.getItem(CUSTOMER_KEY))
            return (
                <Modal isOpen={profileVisible} role="dialog" autoFocus={true} centered={true} className="profile-modal" tabIndex="-1">
                    <ModalHeader tag="h4"> Mon Profil </ModalHeader>
                    <ModalBody className="text-center p-4">
                        <div className="d-flex flex-column flex-md-row">
                            <div className="me-3">
                                {avatar ? (
                                    <div className="mb-2">
                                        <img
                                            className="rounded avatar-md"
                                            src={avatar}
                                            alt="user avatar"
                                        />
                                    </div>
                                ) : (
                                    <div className="avatar-md mx-auto mb-2">
                                        <span className="avatar-title rounded bg-soft bg-primary text-primary fw-bold fs-3">
                                            {`${name.charAt(0).toUpperCase()}${name.charAt(1).toUpperCase()}`}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="flex-grow-1 text-start ms-4">
                                <h5 className="fs-15 mb-1 text-dark fw-bold">
                                    {`${name || ""}`}
                                </h5>

                                <p className="text-muted mb-1">
                                    {`${formatPhoneNumberForDisplay(phone) || ""}`}
                                </p>

                                <p className="text-muted mb-1">
                                    {`${email || ""}`}
                                </p>

                                <p className="text-muted mb-1">
                                    {`${address || ""}`}
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button" data-dismiss="modal" className="btn btn-light"
                            onClick={() => this.onModalToggled("profileVisible")}
                        >
                            Fermer
                        </button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    /**
     * Show dev mode modal.
     * 
     * @returns 
     */
    onDevModeOpened = () => {
        let { devModeVisible, isCopied } = this.state
        if (devModeVisible && localStorage.getItem(CUSTOMER_KEY)) {
            let { dev_options, _id } = JSON.parse(localStorage.getItem(CUSTOMER_KEY))
            const { error, loading, success, secretKey, onGenerate } = this.props
            
            return(
                <Modal isOpen={devModeVisible} role="dialog" autoFocus={true} centered={true}className="dev-mode-modal" tabIndex="-1">
                    <ModalHeader tag="h4"> Mode Développeur </ModalHeader>
                    <ModalBody className="p-4">
                      <div className="d-flex flex-column">
                        <div>
                            <p className="mb-2 text-center">
                                Le mode développeur vous permet de générer/regénérer 
                                une clé secrète afin d'utiliser l'api de RapidPoste. 
                            </p>

                            <Alert color='secondary' className="fade show mt-3" role="alert">
                                <div className='d-flex flex-row align-items-center justify-content-between'>
                                    <span className='mr-1' style={{flex: 1}}>
                                        <span className='text-center'>
                                            Identidiant du client: {' '}
                                            <span
                                                title="Cliquer pour copier"
                                                className='h-cursor'
                                                onClick={() => this.onCopyClicked(_id)}
                                            > 
                                                <strong>{_id}</strong>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </Alert>

                            {(success && secretKey.trim() !== "") && (
                                <Alert color='primary' className="fade show mt-3" role="alert">
                                    <div className='d-flex flex-row align-items-center justify-content-between'>
                                        <span className='mr-1' style={{flex: 1}}>
                                            <span className='text-center'>
                                                {secretKey} 
                                            </span>
                                        </span>
                                        
                                        <span
                                            title="Cliquer pour copier"
                                            className='h-cursor'
                                            onClick={() => this.onCopyClicked(secretKey)}
                                        > 
                                            <i className="bx bx-sm bx-copy-alt" />
                                        </span>
                                    </div>
                                </Alert>
                            )}

                            {isCopied ? (
                                <small className="d-block text-muted fs-12 mt-1">
                                    Copié.e dans le presse-papier
                                </small>
                            ) : null}

                            {(success && secretKey.trim() !== "") && (   
                                <p style={{ lineHeight: 1 }}>
                                    <small className='text-danger d-block mt-1'>
                                        <i>
                                            *Vous devez copier et sauvegarder votre clé secrète dans un endroit sûr 
                                            et sécurisé avant de fermer cette boite de dialogue.
                                        </i>
                                    </small>
                                </p>
                            )}

                            {(error && error != "") ? (
                                <Alert color="danger my-1"> {error} </Alert>
                            ) : null}

                            <div className='text-center'>
                                {loading ? (
                                    <Loader color="primary" />
                                ) : secretKey.trim() === "" && (
                                    <button
                                        type="button" className="btn btn-primary mt-3" onClick={() => onGenerate()}
                                        disabled={secretKey.trim() !== ""}
                                    >
                                        {`${dev_options.client_secret.trim() ? "Regénérer" : "Générer"} une clé secrète`}
                                    </button>
                                )}
                            </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button" data-dismiss="modal" className="btn btn-light"
                            onClick={() => this.onModalToggled("devModeVisible")}
                        >
                            Fermer
                        </button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    /**
     * Show update password modal.
     * 
     * @returns 
     */
    onPasswordOpened() {
        let { passwordVisible } = this.state
        if (passwordVisible) {
            const { error, loading, success, option } = this.props
            const { showCurrentPassword, showNewPassword, showConfirmedPassword, passwords } = this.state
            return (
                <Modal isOpen={passwordVisible} role="dialog" autoFocus={true} centered={true} className="logout-modal" tabIndex="-1">
                    <AvForm onValidSubmit={this.onSubmited}>
                        <ModalHeader tag="h4">
                            Modifier mon mot de passe
                            <small className="d-block text-danger fs-11">
                                *Après avoir modifié votre mot de passe, vous serez déconnecté. Vous devrez donc vous reconnecter.
                            </small>
                        </ModalHeader>
                        <ModalBody>
                            {(error && error != "") ? (
                                <Alert color="danger"> {error} </Alert>
                            ) : null}

                            <div className="mb-3">
                                <AvGroup>
                                    <Label> Mot de passe actuel </Label>
                                    <div className="input-group">
                                        <AvInput
                                            type={`${(showCurrentPassword) ? "text" : "password"}`}
                                            name="current_password"
                                            className="password-control form-control"
                                            minLength="8"
                                            aria-label="toggle-pwd"
                                            aria-describedby="toggle-pwd-input-addon"
                                            required
                                        />
                                        <span
                                            id="toggle-pwd-input-addon"
                                            className="toggle-password-btn input-group-text h-cursor"
                                            onClick={() => this.onPasswordToggled("showCurrentPassword")}
                                        >
                                            <i className={`text-muted bx bx-xs bxs-${(showCurrentPassword) ? "hide" : "show"}`} />
                                        </span>
                                        <AvFeedback>Le mot de passe actuel a au moins 8 caractères.</AvFeedback>
                                    </div>
                                </AvGroup>
                            </div>

                            <div className="mb-3">
                                <AvGroup>
                                    <Label> Nouveau mot de passe </Label>
                                    <div className="input-group">
                                        <AvInput
                                            type={`${(showNewPassword) ? "text" : "password"}`}
                                            name="new_password"
                                            className="password-control form-control"
                                            minLength="8"
                                            aria-label="toggle-pwd"
                                            aria-describedby="toggle-pwd-input-addon"
                                            required
                                            onChange={this.onChanged}
                                        />
                                        <span
                                            id="toggle-pwd-input-addon"
                                            className="toggle-password-btn input-group-text h-cursor"
                                            onClick={() => this.onPasswordToggled("showNewPassword")}
                                        >
                                            <i className={`text-muted bx bx-xs bxs-${(showNewPassword) ? "hide" : "show"}`} />
                                        </span>
                                        <AvFeedback>Le mot de passe a au moins 8 caractères.</AvFeedback>
                                    </div>
                                </AvGroup>
                            </div>

                            <div className="mb-3">
                                <AvGroup>
                                    <Label> Nouveau mot de passe confirmé </Label>
                                    <div className="input-group">
                                        <AvInput
                                            type={`${(showConfirmedPassword) ? "text" : "password"}`}
                                            name="confirmed_password"
                                            className="password-control form-control"
                                            minLength="8"
                                            aria-label="toggle-pwd"
                                            aria-describedby="toggle-pwd-input-addon"
                                            required
                                            onChange={this.onChanged}
                                        />
                                        <span
                                            id="toggle-pwd-input-addon"
                                            className="toggle-password-btn input-group-text h-cursor"
                                            onClick={() => this.onPasswordToggled("showConfirmedPassword")}
                                        >
                                            <i className={`text-muted bx bx-xs bxs-${(showConfirmedPassword) ? "hide" : "show"}`} />
                                        </span>
                                        {((!passwords.same_password) && (passwords.new_password !== "") && (passwords.confirmed_password !== "")) ? (
                                            <p className="m-0 p-0" style={{ width: "100%" }}>
                                                <small className="d-block text-danger mt-1">
                                                    Les nouveaux mots de passes ne correspondent pas.
                                                </small>
                                            </p>
                                        ) : (
                                            <AvFeedback>
                                                Le mot de passe a au moins 8 caractères.
                                            </AvFeedback>
                                        )}
                                    </div>
                                </AvGroup>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {loading ? (
                                <Loader color="warning" />
                            ) : success ? (
                                <Alert color="warning">Redirection...</Alert>
                            ) : (
                                <>
                                    <button
                                        type="button" data-dismiss="modal" className="btn btn-light"
                                        onClick={() => this.onModalToggled("passwordVisible")}
                                    >
                                        Fermer
                                    </button>
                                    <button type="submit" className="btn btn-warning">
                                        Modifier
                                    </button>
                                </>
                            )}
                        </ModalFooter>
                    </AvForm>
                </Modal>
            );
        }
    }


    render() {

        const { t } = this.props
        const { drop, username, isDevModeEnabled } = this.state

        return (
            <React.Fragment>
                <Dropdown
                    isOpen={drop}
                    toggle={this.onDropToggled}
                    className="dropdown header-item px-1 center-ibox"
                >
                    <DropdownToggle className="btn center-box border-0" id="page-header-user-dropdown" tag="button">
                        <span className="rounded-circle center-box header-profile-user custom-user-menu-avatar">
                            <i className="bx bx-user fs-20 align-middle" />
                        </span>{" "}
                        <span className="d-none d-xl-inline-block ms-1 align-middle fs-13">
                            {username}
                        </span>
                        <i className="bx bx-chevron-down d-none d-xl-inline-block align-middle" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem tag="a" href="#" onClick={() => this.onModalToggled("profileVisible")}>
                            <i className="bx bx-user fs-16 align-middle me-1" />
                            <span className="align-middle">{t("Profil")}</span>
                        </DropdownItem>
                        <DropdownItem tag="a" href="#" onClick={() => this.onModalToggled("passwordVisible")}>
                            <i className="bx bx-check-shield fs-16 align-middle me-1" />
                            <span className="align-middle">{t("Modifier mot de passe")}</span>
                        </DropdownItem>
                        {isDevModeEnabled && (
                            <DropdownItem tag="a" href="#" onClick={() => this.onModalToggled("devModeVisible")}>
                                <i className="bx bx-code-curly fs-16 align-middle me-1" />
                                <span className="align-middle">{t("Mode Développeur")}</span>
                            </DropdownItem>
                        )}
                        <DropdownItem tag="a" href="#" onClick={() => this.onModalToggled("logoutVisible")}>
                            <i className="bx bx-power-off fs-16 align-middle me-1 text-danger" />
                            <span className="align-middle text-danger">{t("Déconnexion")}</span>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                {//Add Profile Modal
                    this.onProfileOpened()
                }
                {//Add Logout Modal
                    this.onLogoutOpened()
                }
                {//Add Password Modal
                    this.onPasswordOpened()
                }
                {//Add Dev Mode Modal
                    this.onDevModeOpened()
                }
            </React.Fragment>
        )
    }
}
Profile.propTypes = {
    t: PropTypes.any,
    error: PropTypes.string,
    secretKey: PropTypes.string,
	option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    unauthorized: PropTypes.bool,
    history: PropTypes.object,
    onLogout: PropTypes.func,
    onReset: PropTypes.func,
}
const mapStateToProps = state => ({
    error: state.auth.error,
    loading: state.auth.loading,
    success: state.auth.success,
    unauthorized: state.auth.unauthorized,
    secretKey: state.auth.secretKey,
	option: state.auth.option
})
const mapDispatchToProps = dispatch => ({
    onLogout: (option) => dispatch(authLogout(option)),
    onReset: (all) => dispatch(authReset(all)),
    onUpdate: (data, option) => dispatch(authUpdate(data, option)),
    onGenerate: () => dispatch(authGenerateSecret()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Profile)))