import API, { ORDER_BASE_URL } from './baseService';

export function selectInvoices() {
    return API.get(`${ORDER_BASE_URL}bills/customer`).then((response) => response).catch((error) => error);
}

export function findInvoice(reference, month, year) {
    return API.get(`${ORDER_BASE_URL}bills/find?reference=${reference}&year=${year}&month=${month}`).then((response) => response).catch((error) => error);
}

export function paidInvoice(data) {
    return API.put(`${ORDER_BASE_URL}bills/paid`, data).then((response) => response).catch((error) => error);
}