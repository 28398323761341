import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import Lightbox
import Lightbox from "react-image-lightbox"
import "../../../node_modules/react-image-lightbox/style.css"

// import redux, actions
import { connect } from 'react-redux'
import { listOrders, resetOrders, setOrders } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import custom orders components
import OrderList from 'components/orders/OrderList'
import OrderCard from 'components/orders/OrderCard'
import OrderSearch from 'components/orders/OrderSearch'

// import constants
import { APP_NAME, DATE_FORMATER, ORDERS_LIST, ORDERS_SHOW } from 'helpers/constants'

// import utilities
import { consoleErrorMessage } from 'helpers/utilities'


class MyOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            cardVisible: false,
            query: {
                start: moment(),
                end: moment(),
                code: ""
            }
        }
    }

    componentDidMount() {
        try {// Load orders
            this.onLoaded()
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "cardVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On loaded.
     * 
     */
    onLoaded = () => {
        try {//Load orders
            let { alls, onResetOrders, onListOrders } = this.props
            if (alls.length > 0) onResetOrders(true)
            onListOrders(ORDERS_LIST)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On refreshed.
     * 
     * @param {*} list 
     * @param {*} selected 
     */
    onRefreshed = (list, selected = {}) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Save filter
            onSetOrders({ filtereds: list, filter: selected })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On showed.
     * 
     * @param {*} selected 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { ...selected }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("cardVisible", true)
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * Toggle logo light.
     * 
     * @param {*} zoom 
     * @param {*} uri 
     * @param {*} caption 
     */
    onEnlarged = (zoom = false, uri = "", caption = "") => {
        this.setState({
            picture: {
                preview: uri,
                caption: caption,
                zoom: zoom
            }
        })
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            // Get target
            let { checked, value, name, type } = event.target

            // Save values
            this.setState({
                query: {
                    ...this.state.query,
                    [name]: ((type === "checkbox") ? checked : value)
                }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onRanged = (start, end) => {
        try {
            //Save values
            this.setState({
                query: {
                    ...this.state.query,
                    start: start, end: end
                }
            })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
    * On retrieved.
    *  
    * @returns 
    */
    onRetrieved = () => {
        try {
            //Get state values
            let { code } = this.state.query
            if (code && (code !== "")) {
                //Reset date filter picker
                this.setState({
                    query: { ...this.state.query, start: moment(), end: moment() }
                })

                //Get props values
                let { alls, onSetOrders, onResetOrders } = this.props

                //Reset state
                onResetOrders(false)

                //Search value
                let retrieved = alls.find((item) => (item.code.toLowerCase() === code.toLowerCase()))

                //Save list
                onSetOrders({ filtereds: (retrieved ? [retrieved] : []) })
            }
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On researched.
     *  
     * @returns 
     */
    onResearched = () => {
        try {
            //Get state values
            let { start, end } = this.state.query

            //Reset code filter input
            this.setState({
                query: {
                    ...this.state.query, code: ""
                }
            })

            //Get props values
            let { alls, onSetOrders, onResetOrders } = this.props

            //Format start and end date
            let startStr = start.format(DATE_FORMATER)
            let endStr = end.format(DATE_FORMATER)

            //Search by date/period
            let filtereds = alls.filter((item) => {
                let formated = moment.unix(item.created_at).format(DATE_FORMATER)
                if (startStr === endStr) {
                    return moment(formated).isSame(startStr)
                } else {
                    return moment(formated).isBetween(startStr, endStr)
                }
            })

            //Reset state
            onResetOrders(false)

            //Save list
            onSetOrders({ filtereds: filtereds })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }

    /**
     * On reseted.
     *  
     * @param {*} option 
     * @returns 
     */
    onReseted = (option = "") => {
        try {
            //Get props values
            let { alls, onSetOrders, onResetOrders } = this.props

            //Reset local state
            this.setState({
                query: { start: moment(), end: moment(), code: "" }
            })

            //Reset global state
            onResetOrders(false)

            //Save list
            onSetOrders({ filtereds: alls })
        } catch (error) {
            consoleErrorMessage(error)
        }
    }


    render() {
        const { t, alls, orders, order, filter, loading, success, error, option } = this.props
        const { cardVisible, picture, query } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Mes courses")}</title>
                </Helmet>

                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs
                            title={t("Mes courses")}
                            subtitle={(alls.length > 0) ? (`(${alls.length})`) : ""}
                        />

                        <Row className="mb-2">
                            <Col xl="12">
                                {((error != "") && (option === ORDERS_LIST)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between m-0">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (loading && (option === ORDERS_LIST)) ? (
                                    <Loader color="primary" />
                                ) : (
                                    <Row>
                                        <Col xl="3" sm="12" className="pe-3 pe-xxl-4">
                                            <OrderSearch
                                                onChanged={this.onChanged}
                                                onRanged={this.onRanged}
                                                onRetrieved={this.onRetrieved}
                                                onResearched={this.onResearched}
                                                onReseted={this.onReseted}
                                                query={query}
                                            />
                                        </Col>
                                        <Col xl="9" sm="12" className="ps-3 ps-xxl-4">
                                            <OrderList
                                                alls={alls}
                                                orders={orders}
                                                order={order}
                                                filter={filter}
                                                onShowed={this.onShowed}
                                                onRefreshed={this.onRefreshed}
                                                onReloaded={this.onLoaded}
                                                showReload
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(cardVisible) ? (
                    <OrderCard
                        opened={cardVisible}
                        onClosed={this.onToggled}
                        onEnlarged={this.onEnlarged}
                    />
                ) : null}
                {(picture && picture.zoom) ? (
                    <Lightbox
                        mainSrc={picture.preview}
                        enableZoom={true}
                        imageCaption={picture.caption}
                        onCloseRequest={() => this.onEnlarged()}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
MyOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    alls: PropTypes.array,
    orders: PropTypes.array,
    order: PropTypes.object,
    filter: PropTypes.object,
    onListOrders: PropTypes.func,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
}
const mapStateToProps = state => ({
    alls: state.commandes.orders,
    orders: state.commandes.filtereds,
    order: state.commandes.order,
    filter: state.commandes.filter,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onListOrders: (option) => dispatch(listOrders(option))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(MyOrders)))