import { 
	AUTH_ERROR, 
	AUTH_SECRET, 
	AUTH_LOGIN, 
	AUTH_LOGOUT, 
	AUTH_RESET, 
	AUTH_SUCCESS, 
	AUTH_UNAUTHORIZED, 
	AUTH_UPDATE, 
	AUTH_SESSION
} from "./constants"

export const authLogin = (data, option) => ({
	type: AUTH_LOGIN,
	payload: { data, option },
})

export const authLogout = (option) => ({
	type: AUTH_LOGOUT,
	payload: { option },
})

export const authUpdate = (data, option) => ({
	type: AUTH_UPDATE,
	payload: { data, option },
})

export const authSuccess = (secretKey = "") => ({
	type: AUTH_SUCCESS,
	payload: { secretKey },
})

export const authError = (error) => ({
	type: AUTH_ERROR,
	payload: { error },
})

export const authReset = (all = false) => ({
	type: AUTH_RESET,
	payload: { all },
})

export const isUnauthorized = () => ({
	type: AUTH_UNAUTHORIZED,
})

export const authGenerateSecret = (option = "") => ({
	type: AUTH_SECRET,
	payload: { option },
})

export const authValidateSession = (option) => ({
	type: AUTH_SESSION,
	payload: { option },
})