import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// import moment
import moment from 'moment'

// import print component
import ReactToPrint from 'react-to-print'

// import components
import {
    Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table
} from 'reactstrap'

// import images
import logo from '../../assets/images/logos/logo.png'

// import utilities
import { formatPhoneNumberForDisplay } from 'helpers/utilities'


const Document = React.forwardRef(({ data }, ref) => {
    const {
        code, start_point, delivery_point, round_trip, created_at, completed_at, received_at, delivery_auth, nature, payment, customer, receiver
    } = data

    return (
        <div ref={ref}>
            <Row>
                <Col lg="12">
                    <Card className="shadow-none">
                        <CardBody className="p-4">
                            <table width="100%" role="presentation" border="0" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td style={{ width: "20%" }}>
                                            <img src={logo} height={100} width={100} />
                                        </td>
                                        <td style={{ width: "80%" }}>
                                            <p
                                                style={{
                                                    margin: 0, padding: 0,
                                                    fontSize: "20px", textAlign: "end",
                                                    fontStyle: "italic", fontWeight: "bold"
                                                }}
                                            >
                                                Accusée de réception
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table width="100%" role="presentation" border="0" cellPadding="0" cellSpacing="0" style={{ marginTop: "3rem" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "start" }}>
                                            <span style={{ fontSize: "14px" }}>
                                                Course <span style={{ fontWeight: "bold" }}>#{code}</span>
                                                {" "} du {" "}
                                                <span style={{ display: "block" }}>
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {moment.unix(created_at).format("DD MMM YYYY")}
                                                    </span> {" "} à {" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {moment.unix(created_at).format("HH:mm")}
                                                    </span>
                                                </span>
                                                {(delivery_auth.enabled && (delivery_auth.code && (delivery_auth.code !== ""))) ? (
                                                    <span style={{ display: "block", marginTop: "0.5rem" }}>
                                                        <small style={{ display: "block", color: "#74788d" }}>
                                                            Code de livraison
                                                        </small>
                                                        <span style={{ display: "block" }}>
                                                            {delivery_auth.code}
                                                        </span>
                                                    </span>
                                                ) : null}
                                            </span>
                                        </td>
                                        <td style={{ textAlign: "end" }}>
                                            <span style={{ fontSize: "14px" }}>
                                                <small style={{ display: "block", color: "#74788d" }}>
                                                    Client
                                                </small>
                                                <span style={{ display: "block" }}>
                                                    {customer.name}
                                                </span>
                                                {customer.phone ? (
                                                    <span style={{ display: "block" }}>
                                                        {formatPhoneNumberForDisplay(customer.phone)}
                                                    </span>
                                                ) : null}
                                                {customer.email ? (
                                                    <span style={{ display: "block" }}>
                                                        {customer.email}
                                                    </span>
                                                ) : null}
                                                {customer.address ? (
                                                    <span style={{ display: "block" }}>
                                                        {customer.address}
                                                    </span>
                                                ) : null}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="table-responsive">
                                <table width="100%" role="presentation" border="1" cellPadding="0" cellSpacing="0" style={{ marginTop: "3rem", borderColor: "#eaecee " }}>
                                    <thead className="table-light">
                                        <tr style={{ backgroundColor: "#f6f6f6", textAlign: "center", fontWeight: "bold", fontSize: "0.9rem" }}>
                                            <th style={{ padding: "5px", width: "27%" }} className="border border-light">
                                                Enlèvement
                                            </th>
                                            <th style={{ padding: "5px", width: "27%" }} className="border border-light">
                                                Destination
                                            </th>
                                            <th style={{ padding: "5px", width: "12%" }} className="border border-light">
                                                Nature
                                            </th>
                                            <th style={{ padding: "5px", width: "12%" }} className="border border-light">
                                                Code à <br />la livraison
                                            </th>
                                            <th style={{ padding: "5px", width: "12%" }} className="border border-light">
                                                Aller-Retour
                                            </th>
                                            <th style={{ padding: "5px", width: "10%" }} className="border border-light">
                                                Coût(XOF)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ fontSize: "0.95rem" }}>
                                            <td style={{ textAlign: "center", padding: "10px 5px" }}>
                                                <span>
                                                    <span style={{ fontWeight: "bold", color: "#74788d" }}>
                                                        {formatPhoneNumberForDisplay(start_point.contact)}
                                                    </span>
                                                    <span style={{ display: "block", fontSize: "1rem" }}>
                                                        {start_point.address}
                                                    </span>
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center", padding: "10px 5px" }}>
                                                <span>
                                                    <span style={{ fontWeight: "bold", color: "#74788d" }}>
                                                        {formatPhoneNumberForDisplay(delivery_point.contact)}
                                                    </span>
                                                    <span style={{ display: "block", fontSize: "1rem" }}>
                                                        {delivery_point.address}
                                                    </span>
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center", padding: "10px 5px" }}>
                                                <span style={{ textTransform: "uppercase" }}>
                                                    {nature}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center", padding: "10px 5px" }}>
                                                <span>
                                                    {delivery_auth.enabled ? "Oui" : "Non"}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center", padding: "10px 5px" }}>
                                                <span>
                                                    {round_trip.enabled ? "Oui" : "Non"}
                                                </span>
                                            </td>
                                            <td style={{ textAlign: "center", padding: "10px 5px", fontWeight: "bold" }}>
                                                <span>
                                                    {payment.price}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <table width="100%" role="presentation" border="0" cellPadding="0" cellSpacing="0" style={{ marginTop: "3rem" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "60%" }}>
                                            <span style={{ textAlign: "start", fontSize: "14px" }}>
                                                <span>
                                                    <small style={{ color: "#74788d" }}>
                                                        Réceptionnnée par
                                                    </small>
                                                    <br />
                                                    <span>
                                                        {receiver.name}
                                                    </span>
                                                    {receiver.phone ? (
                                                        <>
                                                            <br />
                                                            <span>
                                                                {formatPhoneNumberForDisplay(receiver.phone)}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {receiver.email ? (
                                                        <>
                                                            <br />
                                                            <span>
                                                                {receiver.email}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    {receiver.address ? (
                                                        <>
                                                            <br />
                                                            <span>
                                                                {receiver.address}
                                                            </span>
                                                        </>
                                                    ) : null}
                                                </span>
                                                <br /> <br />
                                                <span>
                                                    <small style={{ color: "#74788d" }}>
                                                        Réceptionnée le
                                                    </small>
                                                    <br />
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {moment.unix(received_at).format("DD MMM YYYY")}
                                                    </span> {" "} à {" "}
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {moment.unix(received_at).format("HH:mm")}
                                                    </span>
                                                </span>
                                            </span>
                                        </td>
                                        <td style={{ width: "40%" }}>
                                            {((!receiver.in_agency) && (receiver.signature !== "")) ? (
                                                <p style={{ margin: 0, padding: 0, textAlign: "end" }}>
                                                    <img
                                                        src={receiver.signature}
                                                        alt="signature du destinataire"
                                                        width={200} height={200}
                                                    />
                                                </p>
                                            ) : (
                                                <p style={{ margin: 0, padding: 0, fontSize: "14px", textAlign: "end" }}>
                                                    Récupérer en agence
                                                    <br />
                                                    <small style={{ color: "#74788d" }}>
                                                        Contactez le support pour plus d'informations
                                                    </small>
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <table width="100%" role="presentation" border="0" cellPadding="0" cellSpacing="0" style={{ marginTop: "3rem" }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "100%", padding: "4rem" }}>
                                            <p
                                                style={{
                                                    margin: 0, padding: 0,
                                                    fontStyle: "italic", fontSize: "15px",
                                                    color: "#74788d", textAlign: "center"
                                                }}
                                            >
                                                Merci de nous faire confiance !
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
});


const OrderReceipt = ({ order }) => {

    const documentRef = useRef();

    return (
        <React.Fragment>
            {(order && order._id && order.code) ? (
                <Row>
                    <Col xl="3">
                        <ReactToPrint
                            trigger={() => (
                                <button className="btn btn-info">
                                    Télécharger
                                </button>
                            )}
                            content={() => documentRef.current}
                            documentTitle={`accuse_de_reception_#${order.code ? order.code : ""}`}
                        />
                    </Col>
                    <Col xl="12" className="mt-3">
                        <Document data={order} ref={documentRef} />
                    </Col>
                </Row>
            ) : null}
        </React.Fragment>
    )

}
OrderReceipt.propTypes = {
    order: PropTypes.any
}
OrderReceipt.defaultProps = {
    order: {}
}
export default OrderReceipt