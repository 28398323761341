import React from "react"

// import router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

// Import routes
import { protectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// Import layouts components
import Container from "./components/layout/Container"
import Auther from "./components/layout/Auther"

// import custom components
import NoMatch from "components/common/NoMatch"

// Import toast
import { ToastContainer, Slide } from 'react-toastify'
import '../node_modules/react-toastify/dist/ReactToastify.css'

// Import scss
import "./assets/scss/theme.scss"

// import utilities
import { consoleErrorMessage } from "helpers/utilities"


class App extends React.Component {

	constructor(props) {
		super(props)

		// init state
		this.state = {
			hasError: false
		}
	}

	componentDidCatch(error, info) {
		// Affiche une UI de repli    
		this.setState({ hasError: true });

		// Handle error
		consoleErrorMessage({ error, info }, "COMPONENT ERROR")
	}

	render() {
		return (
			<React.Fragment>
				<Router>
					<Switch>
						{publicRoutes.map((route, index) => (
							<AppRoute
								exact
								key={index}
								path={route.path}
								layout={Auther}
								component={route.component}
								isProtected={false}
							/>
						))}

						{protectedRoutes.map((route, index) => (
							<AppRoute
								exact
								key={index}
								path={route.path}
								layout={Container}
								component={route.component}
								isProtected={true}
							/>
						))}

						<Route component={NoMatch} />
					</Switch>
				</Router>
				<ToastContainer
					hideProgressBar newestOnTop
					closeButton={false} icon={false}
					position="top-right" limit={3}
					transition={Slide} autoClose={1000}
					pauseOnFocusLoss={false}
				/>
			</React.Fragment>
		)
	}
}
export default App
