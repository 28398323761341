import React, { Component } from "react"
import PropTypes from 'prop-types'

//i18n
import { I18nextProvider } from "react-i18next"
import i18n from "../../i18n"

// import router
import { withRouter } from "react-router-dom"

// import deep-equal
import equal from "deep-equal"

// import redux, actions
import { connect } from "react-redux"

// import actions
import {
	authValidateSession, changeLayout, changeLayoutWidth, changePreloader, changeTopbarTheme
} from "../../store/actions"

// import customs components
import Header from "./Header"
import Footer from "./Footer"
import Navbar from "./Navbar"

// import constants
import { USER_SESSION } from "helpers/constants"

// import utilities
import { consoleErrorMessage } from "helpers/utilities"


class Container extends Component {

	constructor(props) {
		super(props)

		this.state = {
			isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
			menuOpened: false,
		}
	}

	componentDidMount() {
		try {
			const {
				isPreloader, layoutWidth, topbarTheme, onChangeLayout,
				onChangeLayoutWidth, onChangeTopbarTheme, onValidateUserSession,
			} = this.props

			//Handle Preloader
			if (isPreloader === true) {
				document.getElementById("preloader").style.display = "block"
				document.getElementById("status").style.display = "block"
				document.getElementById("layout-wrapper").style.display = "none"

				// Launch validate session request
				onValidateUserSession(USER_SESSION)
			} else {
				document.getElementById("preloader").style.display = "none"
				document.getElementById("status").style.display = "none"
				document.getElementById("layout-wrapper").style.display = "block"
			}

			// Scroll Top to 0
			window.scrollTo(0, 0)

			// Change layout type
			onChangeLayout("horizontal")

			// Change topbar, layout theme
			if (topbarTheme) onChangeTopbarTheme(topbarTheme)
			if (layoutWidth) onChangeLayoutWidth(layoutWidth)
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	componentDidUpdate(prevProps) {
		try {//Handle state changes
			if (!equal(prevProps, this.props)) {
				let { isPreloader, loading, option, onChangePreloader } = this.props
				if (isPreloader === true && option === USER_SESSION && !loading) {
					setTimeout(function () {
						document.getElementById("preloader").style.display = "none"
						document.getElementById("status").style.display = "none"
						document.getElementById("layout-wrapper").style.display = "block"

						// Update preloader
						onChangePreloader(false)
					}, 500)
				}
			}
		} catch (error) {
			consoleErrorMessage(error)
		}
	}

	/**
	 * Menu opened events.
	 * 
	 */
	onMenuOpened = () => {
		this.setState(prevState => ({
			menuOpened: !prevState.menuOpened,
		}))
	}

	render() {

		const { menuOpened } = this.state
		const { children } = this.props

		return (
			<React.Fragment>
				<I18nextProvider i18n={i18n}>
					<div id="preloader">
						<div id="status">
							<div className="spinner-chase">
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
								<div className="chase-dot"></div>
							</div>
						</div>
					</div>
					<div id="layout-wrapper">
						<Header
							menuOpened={menuOpened}
							onMenuOpened={this.onMenuOpened}
						/>

						<Navbar menuOpened={menuOpened} />

						<div className="main-content">
							{children}
						</div>

						<Footer />
					</div>
				</I18nextProvider>
			</React.Fragment>
		)
	}

}
Container.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
	isPreloader: PropTypes.bool,
	error: PropTypes.string,
	option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
	unauthorized: PropTypes.bool,
}
const mapStateToProps = state => ({
	...state.ground,
	error: state.auth.error,
	option: state.auth.option,
    loading: state.auth.loading,
    success: state.auth.success,
	unauthorized: state.auth.unauthorized,
})
const mapDispatchToProps = dispatch => ({
	onChangeLayout: type => dispatch(changeLayout(type)),
	onChangeLayoutWidth: width => dispatch(changeLayoutWidth(width)),
	onChangeTopbarTheme: theme => dispatch(changeTopbarTheme(theme)),
	onChangePreloader: isPreloader => dispatch(changePreloader(isPreloader)),
	onValidateUserSession: option => dispatch(authValidateSession(option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container))