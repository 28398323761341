import {
	ORDERS_CALCULATE, ORDERS_ERROR, ORDERS_GET, ORDERS_INSERT, ORDERS_RESET, ORDERS_SELECT, ORDERS_SET, ORDERS_SUCCESS
} from "./constants"


const INITIAL_STATE = {
	orders: [],
	filtereds: [],
	order: {},
	filter: {},
	error: "",
	option: "",
	loading: false,
	success: false
}

const commandes = (state = INITIAL_STATE, action) => {

	const { payload, type } = action

	switch (type) {
		case ORDERS_GET:
		case ORDERS_SELECT:
		case ORDERS_INSERT:
		case ORDERS_CALCULATE:
			return {
				...state,
				error: "",
				option: payload.option,
				loading: true,
				success: false
			}

		case ORDERS_SUCCESS:
			return {
				...state,
				orders: payload.data.orders ? payload.data.orders.reverse() : state.orders,
				filtereds: payload.data.orders ? payload.data.orders : state.filtereds,
				order: payload.data.order ? payload.data.order : payload.data.cost ?
					{ ...state.order, cost: payload.data.cost } : state.order,
				option: payload.option,
				loading: false,
				success: true
			}

		case ORDERS_ERROR:
			return {
				...state,
				error: payload.error,
				option: payload.option,
				loading: false
			}

		case ORDERS_SET:
			return {
				...state,
				orders: (payload.orders) ? payload.orders : state.orders,
				filtereds: (payload.filtereds) ? payload.filtereds : state.filtereds,
				order: (payload.order) ? payload.order : state.order,
				filter: (payload.filter) ? payload.filter : state.filter,
				error: (payload.error) ? payload.error : state.error,
				option: (payload.option) ? payload.option : state.option,
				loading: (payload.loading) ? payload.loading : state.loading,
				success: (payload.success) ? payload.success : state.success
			}

		case ORDERS_RESET:
			return {
				...state,
				orders: (payload.all) ? INITIAL_STATE.orders : state.orders,
				filtereds: (payload.all) ? INITIAL_STATE.filtereds : state.filtereds,
				order: (payload.all) ? INITIAL_STATE.order : state.order,
				filter: (payload.all) ? INITIAL_STATE.filter : state.filter,
				error: INITIAL_STATE.error,
				option: INITIAL_STATE.option,
				loading: INITIAL_STATE.loading,
				success: INITIAL_STATE.success
			}

		default:
			return { ...state }
	}

}

export default commandes