import { takeEvery, put, call } from "redux-saga/effects"

// import actions
import { errorInvoices, successInvoices } from "./actions"
import { isUnauthorized } from "store/actions"

// import constants
import { INVOICES_FIND, INVOICES_SELECT, INVOICES_UPDATE } from "./constants"

// import errors
import { errorMessage } from "helpers/erreurs"

// import utilities
import { isSuccessfulRequest, consoleErrorMessage } from "helpers/utilities"

// import services
import { findInvoice, paidInvoice, selectInvoices } from "services/invoiceService"


// Invoices errors messages
const INVOICES_ERRORS = {
    "UPDATE": {},
    "FIND": {},
    "SELECT": {}
}

function* onSelectInvoices({ payload }) {
    try {
        let { option } = payload
        const response = yield call(selectInvoices)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successInvoices({
                invoices: (response.data ? response.data : [])
            }, option))
        } else {
            consoleErrorMessage(response, "GET INVOICES ERROR")
            let { unauth, message } = errorMessage(response, INVOICES_ERRORS.SELECT)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "SELECT INVOICES ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onUpdateInvoice({ payload }) {
    try {
        let { data, option } = payload
        const response = yield call(paidInvoice, data)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            const list = yield call(selectInvoices)
            if (isSuccessfulRequest(list.status, list.statusText)) {
                yield put(successInvoices({
                    invoices: (list.data ? list.data : [])
                }, option))
            } else {
                consoleErrorMessage(list, "FIND INVOICE ERROR")
                let { unauth, message } = errorMessage(list)
                yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : option)))
                if (unauth) yield put(isUnauthorized())
            }
        } else {
            consoleErrorMessage(response, "FIND INVOICE ERROR")
            let { unauth, message } = errorMessage(response, INVOICES_ERRORS.UPDATE)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "UPDATE INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}

function* onFindInvoice({ payload }) {
    try {
        let { reference, month, year, option } = payload
        const response = yield call(findInvoice, reference, month, year)
        if (isSuccessfulRequest(response.status, response.statusText)) {
            yield put(successInvoices({
                invoices: (response.data ? response.data : [])
            }, option))
        } else {
            consoleErrorMessage(response, "FIND INVOICE ERROR")
            let { unauth, message } = errorMessage(response, INVOICES_ERRORS.FIND)
            yield put(errorInvoices((unauth ? "" : message), (unauth ? "" : option)))
            if (unauth) yield put(isUnauthorized())
        }
    } catch (error) {
        consoleErrorMessage(error, "FIND INVOICE ERROR")
        yield put(errorInvoices(errorMessage(error).message, payload.option))
    }
}


function* InvoiceSaga() {
    yield takeEvery(INVOICES_SELECT, onSelectInvoices)
    yield takeEvery(INVOICES_FIND, onFindInvoice)
    yield takeEvery(INVOICES_UPDATE, onUpdateInvoice)
}

export default InvoiceSaga