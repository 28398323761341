import React from "react"
import PropTypes from 'prop-types'

// import route
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"


const Auther = ({ children, location }) => {

	return (
		<React.Fragment>
			<div className={`${location.pathname.includes("/login") ? "auth-pages" : ""}`}>
				{children}
			</div>
		</React.Fragment>
	)

}
Auther.propTypes = {
	children: PropTypes.node,
	t: PropTypes.any,
	location: PropTypes.object,
}
export default withRouter(withTranslation()(Auther))