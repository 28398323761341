import React from "react"
import { Redirect } from "react-router-dom"

// Login
import Login from "../pages/authentication/Login"

// Orders
import MyOrders from "pages/my-orders"
import CreateOrders from "pages/create-orders"
import SearchOrders from "pages/searches-orders"

// Invoices
import MyInvoices from "pages/my-invoices"
import SearchInvoices from "pages/searches-invoices"

// MyReceipt
import MyReceipt from "pages/my-receipt"

// PrivatePolicy
import PrivatePolicy from "pages/authentication/PrivatePolicy"

const protectedRoutes = [
	{ path: "/mes-courses", component: MyOrders },
	{ path: "/ajouter-course", component: CreateOrders },
	{ path: "/mes-factures", component: MyInvoices },
	{ path: "/recherches-courses", component: SearchOrders },
	{ path: "/recherches-factures", component: SearchInvoices },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/mes-courses" /> },
]

const publicRoutes = [
	{ path: "/login", component: Login },
	{ path: "/accuse-de-reception/:order", component: MyReceipt },
	{ path: "/confidentialite-et-utilisation", component: PrivatePolicy },
]

export { protectedRoutes, publicRoutes }
